export default [
    {
        name: 'payroll',
        popular: true,
        model: 0,
        items: [
            {name: 'easy_pay', pic: require('./features-assets/undraw_Success_factors_re_ce93.svg')},
        ],
    },
    {
        name: 'scheduling',
        popular: true,
        model: 0,
        items: [
            {name: 'right_place', pic: require('./features-assets/undraw_schedule_pnbk.svg')},
            {name: 'automatic', pic: require('./features-assets/undraw_shared_workspace_hwky.svg')},
        ],
    },
    {
        name: 'hr',
        popular: true,
        model: 0,
        items: [
            {name: 'onboarding', pic: require('./features-assets/undraw_file_manager_j85s.svg')},
        ],
    },
    {
        name: 'reports',
        popular: false,
        model: 0,
        items: [
            {name: 'generate', pic: require('./features-assets/undraw_Documents_re_isxv.svg')},
        ],
    },
    {
        name: 'communication',
        popular: false,
        model: 0,
        items: [
            {name: 'conversation', pic: require('./features-assets/undraw_Share_opinion_re_4qk7.svg')},
            {name: 'bulletins', pic: require('./features-assets/undraw_Add_notes_re_ln36.svg')},
        ],
    },
    {
        name: 'dashboard',
        popular: false,
        model: 0,
        items: [
            {name: 'widgets', pic: require('./features-assets/undraw_dashboard_nklg.svg')},
        ],
    },
    {
        name: 'sales_projection',
        popular: false,
        model: 0,
        items: [
            {name: 'ai', pic: require('./features-assets/undraw_Artificial_intelligence_re_enpp.svg')},
        ],
    },
    {
        name: 'kpi',
        popular: false,
        model: 0,
        items: [
            {name: 'integration', pic: require('./features-assets/undraw_Data_re_80ws.svg')},
            {name: 'widgets', pic: require('./features-assets/undraw_data_processing_yrrv (1).svg')},
        ],
    },
    {
        name: 'todo',
        popular: false,
        model: 0,
        items: [
            {name: 'create', pic: require('./features-assets/undraw_To_do_list_re_9nt7.svg')},
        ],
    },
    {
        name: 'checklists',
        popular: false,
        model: 0,
        items: [
            {name: 'repeating', pic: require('./features-assets/undraw_task_list_6x9d.svg')},
        ],
    },
    {
        name: 'filesystem',
        popular: false,
        model: 0,
        items: [
            {name: 'share', pic: require('./features-assets/undraw_my_files_swob.svg')},
        ],
    },
];
