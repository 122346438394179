<template>
  <v-card class="flex-grow-1">
    <div v-if="showPopular && feature.popular" class="popular-badge rounded-pill amber py-1 pr-3 d-flex justify-center align-center elevation-2">
      <v-icon size="25" right class="mr-1">mdi-star</v-icon>
      <span class="subtitle-2">{{ $t('features.popular') }}</span>
    </div>

    <v-card-title color="primary">
      <span>{{ $t('features.' + feature.name + '_title') }}</span>
    </v-card-title>

    <v-carousel v-model="carousel" :height="imageHeight" hide-delimiters :show-arrows="feature.items.length > 1">
      <v-carousel-item v-for="(item, i) in feature.items" :key="i">
        <img class="item-img" :src="item.pic">
      </v-carousel-item>
    </v-carousel>

    <v-carousel v-model="carousel" :height="textHeight" hide-delimiters :show-arrows="false">
      <v-carousel-item v-for="(item, i) in feature.items" :key="i">
        <v-card-subtitle class="feature-item-text-container text-pre-wrap d-flex flex-column">
          <span class="subtitle-1 font-weight-bold">{{ $t('features.' + feature.name + '_' + item.name + '_kicker') }}</span>
          <span class="feature-item-text">{{ $t('features.' + feature.name + '_' + item.name) }}</span>
        </v-card-subtitle>
      </v-carousel-item>
    </v-carousel>

    <v-card-actions v-if="hasCardActions">
      <slot name="card-actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    feature: Object,
    showPopular: Boolean,
    imageHeight: String,
    textHeight: String,
  },
  computed: {
    hasCardActions() {
      return true;
    }
  },
  data() {
    return {
      carousel: 0,
    }
  }
}
</script>

<style scoped>
.item-img {
  height: 100%;
  width: 100%;
}

.popular-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  transform: translate3d(-20px, -50%, 0);
}

.popular-badge > span {
  line-height: normal;
}

.feature-item-text-container {
  height: 100%;
}

.feature-item-text {
  overflow: auto;
}
</style>
